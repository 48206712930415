// Variable overrides
$sidebar-light-nav-link-hover-bg: lightgrey;
$colour-wmts: #e6625e;
$colour-wms : #ff9966;
$colour-wfs : #fed978;
$colour-api : #9f71e3;
$colour-vts : #39f;

$colour-europa-reflexblue: #004c99;
$colour-europa-pantone320: #00a1b1;
$colour-europa-rebeccapruple: #663399;

$colour-europa50-reflexblue: #7fa5cc;
$colour-europa50-pantone320: #7fd0db;
$colour-europa50-rebeccapruple: #b299cc;

// scss-docs-start theme-colors-map
$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $colour-europa-reflexblue,
    "colour-wmts": $colour-wmts,
    "colour-wms" : $colour-wms,
    "colour-wfs" : $colour-wfs,
    "colour-api" : $colour-api,
    "colour-vts" : $colour-vts,
    "colour-europa-reflexblue": $colour-europa-reflexblue,
    "colour-europa-pantone320": $colour-europa-pantone320,
    "colour-europa-rebeccapruple": $colour-europa-rebeccapruple,
    "colour-europa50-reflexblue": $colour-europa50-reflexblue,
    "colour-europa50-pantone320": $colour-europa50-pantone320,
    "colour-europa50-rebeccapruple": $colour-europa50-rebeccapruple,
  ),
  $theme-colors
);
